.SwitchWrapper .itemBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: var(--background);
  color: var(--text);
  border-width: 0px;
}

.SwitchWrapper .itemBtn:hover {
  background-color: var(--primary2);
}
