.Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 400px;
  background-color: #2f4858;
}

.Footer h2 {
  color: white;
  text-align: center;
}
.Footer h3 {
  color: var(--primary1);
  text-align: center;
}

.Footer a {
  color: white;
  text-decoration: none;
}

.Footer .socialWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .Footer {
    flex-direction: column;
    height: 500px;
    margin-top: 20px;
  }
}
