.DashboardScreen {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
}

.DashboardScreen h1 {
  color: var(--darkBlue);
}

.DashboardScreen .downloadExamsResults {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(239, 234, 234);
  border-color: rgb(239, 234, 234);
  min-width: 100px;
  margin: 10px;
}
