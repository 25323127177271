.InputTag {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  border: solid 0.2px;
  border-color: var(--lightText);
  border-radius: 5px;
  padding: 5px;
}

.InputTag .Tag {
  margin: 5px;
}

.InputTag .Tag div {
  color: white;
}
