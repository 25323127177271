@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
	/* color: var(--dark-gray-blue); */
	--primary1: #9bde7e;
	--primary2: #accfe9;
	--primary3: #3a8688;
	--background: #faf9f6;
	--text: #263238;
	--lightText: #adadad;
	--warning: #ffc14f;
	--danger: #ff6868;
	--success: #67c06b;
	--info: #accfe9;
	--blue: #4bbfef;
	--parper: #8e6cca;
	--darkBlue: rgb(53, 125, 190);
	--orange: #f28705;
	--light-blue: rgb(203, 243, 245);
	--light-orange: rgb(249, 238, 224);
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

div {
	color: var(--text);
}

.light-text {
	color: var(--lightText);
}