.StepCard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  margin: 10px;
  max-width: 1000px;
  overflow: hidden;
}
.StepCard .line {
  position: absolute;
  width: 2px;
  height: 120px;
  background-color: var(--darkBlue);
}

.StepCard .titleWrapper {
  margin: 0px 10px;
}

.StepCard .titleWrapper h2 {
  color: var(--darkBlue);
}

.StepCard .stepImage {
  max-width: 150px;
  max-height: 150px;
}

.StepCard .stepCornerImg {
  align-self: flex-end;
}

@media screen and (max-width: 600px) {
  .StepCard .stepCornerImg {
    display: none;
  }
  .StepCard .stepImage {
    max-width: 100px;
    max-height: 100px;
  }
}
