.SampleExamQuestions table .titleQuestionText {
  color: var(--lightText);
}

.SampleExamQuestions table .answerQuestionText > div {
  padding: 10px;
  color: var(--text);
  text-align: center;
  background-color: rgb(188, 245, 229);
  min-width: 100px;
  border-radius: 5px;
}

.StepTwoOptionModal ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  align-items: center;
}

.StepTwoOptionModal ul li {
  padding: 10px;
  border-radius: 5px;
  min-width: 200px;
  margin: 10px 10px 0px -10px;
  text-align: center;
  background-color: var(--primary2);
}

.SampleExamQuestions .matchingQuestionWrapper {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 600px) {
  .SampleExamQuestions .matchingQuestionWrapper {
    display: flex;
    flex-direction: column;
  }
}
