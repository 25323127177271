.languageWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.languageWrapper .languageCard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 100px;
  width: 100px;
  border-radius: 10px;
  margin: 0px 10px;
  cursor: pointer;
  font-weight: bold;
}

.languageWrapper .letterLanguageCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  top: -20px;
  background-color: #23bdee;
  height: 40px;
  width: 40px;
  color: white;
}
