.sidebar {
	display: flex;
	position: fixed;
	z-index: 99;
	flex: 1;
	background-color: red;
	flex-direction: column;
	min-height: 100vh;
	width: 200px;
	transition: width 0.5s;
}

.closeSideBar {
	width: 80px;
}

.showtitle {
	display: block;
}

.hidetitle {
	display: none;
}

.sidebar .top {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #86c494;
	cursor: pointer;
}

.sidebar .down {
	display: flex;
	flex-direction: column;
	background-color: #2f4858;
	flex: 10;
}

.sidebar .dot {
	height: 10px;
	width: 10px;
	background-color: white;
	border-radius: 5px;
}

.sidebar #tab {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	text-decoration: none;
	color: white;
	margin: 10px 0px 10px 20px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	padding: 10px;
	cursor: pointer;
}

.sidebar .activeTab {
	background-color: var(--primary1);
}

.sidebar span {
	margin: 0px 10px;
}

.sidebar .activeText {
	color: #2f4858;
	font-weight: bold;
}

/* @media screen and (max-width: 600px) {
	.sidebar {
		max-width: 80px;
	}

	.showtitle {
		display: none;
	}




} */

@media screen and (max-width: 600px) {
	.sidebar {
		position: fixed;
		transition: transform 0.5s linear;
		z-index: 99;
	}

	.closeSideBar {
		transform: translate(-200px);
		display: none;
	}

	.openSideBar {
		transform: translate(0px);
	}
}