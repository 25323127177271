.CustomExam {
  position: relative;
  margin-bottom: 150px;
}

.CustomExam .StepTwoThreeWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  max-width: 1000px;
  margin: 0px 10px;
}

.CustomExam .StepTwoThreeWrapper > div {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .CustomExam .StepTwoThreeWrapper {
    flex-direction: column;
    align-items: center;
  }
}
