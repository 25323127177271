.emptyGroup {
  position: relative;
}
.emptyGroup .imageWrapper {
  margin-top: 50px;
}
.emptyGroup .createWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 150px;
  margin-left: 230px;
}
.emptyGroup .addBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  background-color: var(--primary1);
  border-radius: 20px;
  cursor: pointer;
}
.emptyGroup .imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
