.TabsNav {
  margin-top: 10px;
}
.TabsNav .tabs {
  display: flex;
}
.TabsNav .Select {
  display: none;
}

@media screen and (max-width: 600px) {
  .TabsNav .tabs {
    display: none;
  }
  .TabsNav .Select {
    display: flex;
  }
}
