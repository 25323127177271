.CompetitionScreen h1 {
  color: var(--darkBlue);
}

.CompetitionScreen div h4 {
  color: var(--primary2);
}

.CompetitionScreen .SpinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
