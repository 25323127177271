.StepTwo {
  margin: 10px;
  margin-bottom: 150px;
}

.StepTwo .minQuestionNum {
  color: var(--orange);
  font-weight: 500;
}

.StepTwo .nextBtn {
  background-color: var(--darkBlue);
  border-width: 0px;
  min-width: 150px;
}

.StepTwo .btnsWrapper {
  justify-content: space-around;
}

.StepTwo .prevBtn {
  background-color: var(--primary3);
  border-width: 0px;
  min-width: 150px;
}
