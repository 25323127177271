.StepTwoCobe {
  margin: 10px;
  max-width: 500px;
}

.StepTwoCobe .StepTwoWrapper {
  padding: 10px;
}

.StepTwoCobe h2 {
  color: var(--darkBlue);
}

.StepTwoCobe button {
  display: flex;
  justify-content: center;
  max-width: 200px;
  background-color: var(--primary1);
  border-color: var(--primary1);
}

.StepTwoCobe button:hover {
  background-color: var(--primary1);
  border-color: var(--primary1);
  opacity: 0.8;
}

.StepTwoCobe button div {
  max-width: 200px;
  color: white;
  font-weight: bold;
}

.StepTwoCobeModal .minQuestionNum {
  color: var(--orange);
  font-weight: 500;
}
