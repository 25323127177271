.Header {
	display: flex;
	flex: 1;
	background-color: white;
	height: 80px;
}

.Header .menuWrapper {
	display: none;
}

.Header nav {
	display: flex;
	flex: 1;
	flex-direction: row;
}

.Header nav img {
	height: 60px;
	width: 60px;
	margin-left: 10px;
}

.Header .leftSideWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
}

.Header .rightSideWrapper {
	display: flex;
	flex: 10;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	margin: 0px 10px;
}

@media screen and (max-width: 600px) {
	.Header .menuWrapper {
		display: block;
	}
}