.StepThree {
  margin-left: 10px;
  max-width: 1000px;
  margin-bottom: 100px;
}

.StepThree h3 {
  color: var(--darkBlue);
}

.StepThree .createSampleExamBtn {
  background-color: var(--darkBlue);
  border-color: var(--darkBlue);
  min-width: 150px;
}

.StepThree .downloadSampleExam {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(239, 234, 234);
  border-color: rgb(239, 234, 234);
  min-width: 100px;
}

.StepThree .downloadSampleExam:hover {
  background-color: rgb(239, 234, 234);
  border-color: rgb(239, 234, 234);
  opacity: 0.8;
}

.StepThree .createSampleExamBtn:hover {
  background-color: var(--darkBlue);
  border-color: var(--darkBlue);
  opacity: 0.8;
}

.StepThree .dargWrapper {
  min-height: 40px;
  max-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: 2px solid transparent;
  font-size: 1.6em;
  margin-bottom: 10px;
  cursor: pointer;
}
.StepThree .dargAble {
  background-color: var(--orange);
}

.StepThree .dragDisable {
  background-color: white;
}

@media screen and (max-width: 600px) {
  .StepThree .matchingQuestionWrapper {
    display: flex;
    flex-direction: column;
  }
}
