.StepThreeCobe {
  margin: 10px;
  max-width: 500px;
}

.StepThreeCobe .StepThreeWrapper {
  padding: 10px;
}

.StepThreeCobe h2 {
  color: var(--darkBlue);
}

.StepThreeCobe button {
  display: flex;
  justify-content: center;
  max-width: 150px;
  background-color: var(--parper);
  border-color: var(--parper);
}

.StepThreeCobe button div {
  max-width: 200px;
  color: white;
  font-weight: bold;
}

.StepThreeCobeModal h3 {
  color: var(--darkBlue);
}

.StepThreeCobeModal .createExamBtn {
  background-color: var(--darkBlue);
  border-color: var(--darkBlue);
  min-width: 150px;
}

.StepThreeCobeModal .createExamBtn:hover {
  background-color: var(--darkBlue);
  border-color: var(--darkBlue);
  opacity: 0.8;
}

.StepThreeCobeModal .dargWrapper {
  min-height: 40px;
  max-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: 2px solid transparent;
  font-size: 1.6em;
  margin-bottom: 10px;
  cursor: pointer;
}

.StepThreeCobeModal .dargAble {
  background-color: var(--orange);
}

.StepThreeCobeModal .dragDisable {
  background-color: white;
}
