.StepOneCobe {
  margin: 10px;
  max-width: 1000px;
}

.StepOneCobe h2 {
  color: var(--darkBlue);
}

.StepOneCobe .attachFilesWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 200px;
  margin: 0px 5px;
  border-radius: 10px;
  color: var(--text);
  background-color: var(--light-orange);
  padding: 10px;
  cursor: pointer;
}

.StepOneCobe .attachedImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin-top: 10px;
}

.StepOneCobe .attachedImageWrapper > img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .StepOneCobe .inputRow {
    display: flex;
    flex-direction: column;
  }
}
