.AppDescription {
  position: relative;
  margin-top: 20px;
}

.AppDescription .phoneWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  border-radius: 10px;
  background-color: #eaedee;
  max-width: 600px;
  padding: 10px;
}

.AppDescription .infoWrapper {
  margin: 0px 20px;
}

.AppDescription .downloadNowWrapper {
  display: flex;
  background-color: white;
  justify-content: center;
  border-radius: 5px;
  margin: 10px 0px;
}
.AppDescription .storesWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.AppDescription .phoneImg {
  max-height: 300px;
  align-self: center;
}

.AppDescription .title {
  color: var(--orange);
  text-align: center;
}

@media screen and (max-width: 600px) {
  .AppDescription .phoneImg {
    display: none;
  }
}
