.StepTwoWritten .wrapper {
  margin: 10px;
}
.StepTwoWritten .wrapper h2 {
  color: var(--darkBlue);
}

.StepTwoWritten .topicWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
}
