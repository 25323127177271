.Features {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Features h1 span {
  color: var(--orange);
}

@media screen and (max-width: 600px) {
  .Features {
    flex-direction: column;
  }
  .Features .featureImage {
    max-width: 300px;
  }
}
