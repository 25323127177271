.HelpVideo {
  cursor: pointer;
}
.videosBodyWrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.videosBodyWrapper .videoWrapper {
  display: flex;
  flex: 10;
}
.videosBodyWrapper .buttonsWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px 10px;
}

.videosBodyWrapper .buttonsWrapper .nxtBtn {
  background-color: blueviolet;
}
.videosBodyWrapper .buttonsWrapper .prevBtn {
  background-color: blueviolet;
}

.videosBodyWrapper .videosDots {
  height: 20px;
  width: 20px;
  border-radius: 10px;
}

.videosBodyWrapper .videoDotsWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .videosBodyWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .videosBodyWrapper .videoWrapper {
    display: flex;
    flex: 10;
  }
  .videosBodyWrapper .buttonsWrapper {
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 10px 0px;
  }
  .videosBodyWrapper .videoDotsWrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
}
