.TeacherNavigation {
	display: flex;
	flex-direction: row;
	flex: 1;
	min-height: 100vh;
	background-color: var(--background);
}

.TeacherNavigation .wrapper {
	flex: 1;
	background-color: var(--background);

}

.TeacherNavigation .wrapper .languageSelect {
	max-width: 200px;
}

.TeacherNavigation .sidebarWrapper {
	min-width: 80px;
	transition: min-width 0.5s;
}

.TeacherNavigation .openSidebar {
	min-width: 200px;
	transition: min-width 0.5s;
}

@media screen and (max-width: 600px) {
	.TeacherNavigation .sidebarWrapper {
		min-width: 0px;
	}

	/* .TeacherNavigation .openSidebar {
		width: 0px;
		transition: min-width 0.5s;
	} */
}