.StepOneWritten {
  margin: 10px;
  max-width: 1000px;
  margin-bottom: 150px;
}
.StepOneWritten .nextBtn {
  min-width: 200px;
  background-color: var(--darkBlue);
  border-color: var(--darkBlue);
}
.StepOneWritten .nextBtn:hover {
  background-color: var(--darkBlue);
  opacity: 0.8;
}

.StepOneWritten .step1Footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .StepOneWritten .inputRow {
    display: flex;
    flex-direction: column;
  }
}
