.loginWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.rtlBox {
  flex-direction: row-reverse;
}

.loginWrapper .title span {
  color: var(--orange);
  font-weight: bold;
}

.loginWrapper .title {
  margin-top: 20px;
  color: var(--text);
}

.loginImage1 {
  object-fit: contain;
  width: 600px;
}

.loginWrapper div {
  flex: 1;
}

.loginWrapper .videoPlayerBtnWrapper {
  cursor: pointer;
}

.loginWrapper .loginImageWrapper {
  position: relative;
}

.loginWrapper .homeworkCard {
  position: absolute;
  top: 150px;
}

.loginWrapper .examCard {
  max-width: 500px;
}

@media screen and (max-width: 600px) {
  .loginWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .loginImage1 {
    height: 300px;
    width: 300px;
    object-fit: contain;
  }

  .loginWrapper .homeworkCard {
    position: relative;
    top: 0px;
  }
}
