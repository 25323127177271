.MCQuestionSortable {
  margin-bottom: 10px;
}

.MCQuestionSortable .mcqAnswer {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.MCQuestionSortable .mcqAnswer > span {
  background-color: rgb(188, 245, 229);
  padding: 10px;
  border-radius: 5px;
}
